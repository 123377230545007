"use strict";
/// <reference types="jquery"/>
/// <reference types="bootstrap"/>
exports.__esModule = true;
exports.Tabs = void 0;
require("bootstrap/js/dist/tab");
var Tabs = /** @class */ (function () {
    function Tabs(config) {
        this.config = config;
    }
    Tabs.prototype.run = function () {
        jQuery(this.config.selector).on('click', function (e) {
            e.preventDefault();
            jQuery(this).tab('show');
        });
    };
    return Tabs;
}());
exports.Tabs = Tabs;
