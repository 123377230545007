"use strict";
exports.__esModule = true;
exports.StaticAssets = void 0;
var StaticAssets = /** @class */ (function () {
    function StaticAssets() {
    }
    StaticAssets.prototype.run = function () {
        return [];
    };
    return StaticAssets;
}());
exports.StaticAssets = StaticAssets;
