"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.SwiperSlider = void 0;
var swiper_1 = __importStar(require("swiper"));
require("swiper/swiper.scss");
require("swiper/components/navigation/navigation.scss");
require("swiper/components/pagination/pagination.scss");
require("swiper/components/lazy/lazy.scss");
require("./../../scss/slider.scss");
swiper_1["default"].use([swiper_1.Navigation, swiper_1.Pagination, swiper_1.Autoplay, swiper_1.Lazy]);
var SwiperSlider = /** @class */ (function () {
    function SwiperSlider(config) {
        this.container = config.container;
        if (config.options) {
            this.options = config.options;
        }
    }
    SwiperSlider.prototype.run = function () {
        this.instance = new swiper_1["default"](this.container, this.options);
        return this.instance;
    };
    return SwiperSlider;
}());
exports.SwiperSlider = SwiperSlider;
