"use strict";
/// <reference types="jquery"/>
/// <reference types="fancybox"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.ModalMedia = void 0;
require("@fancyapps/fancybox/dist/jquery.fancybox.js");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");
var index_1 = require("./index");
var ModalMedia = /** @class */ (function (_super) {
    __extends(ModalMedia, _super);
    function ModalMedia(config) {
        var _this = _super.call(this, config) || this;
        var mediaOptions = {
            type: 'iframe',
            helpers: {
                media: true
            }
        };
        _this.options = jQuery.extend(true, _this.options, mediaOptions);
        return _this;
    }
    ModalMedia.prototype.run = function () {
        jQuery(this.elementsSelector).fancybox(this.options);
    };
    return ModalMedia;
}(index_1.Modal));
exports.ModalMedia = ModalMedia;
