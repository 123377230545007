"use strict";
exports.__esModule = true;
require("core-js/stable/dom-collections/for-each");
require("classlist-polyfill");
require("element-closest-polyfill");
var ease_component_1 = require("ease-component");
var image_1 = require("./modals/image");
var media_1 = require("./modals/media");
var scroll_to_1 = require("./scroll-to");
var static_assets_1 = require("./static-assets");
var swiper_1 = require("./swiper");
var tabs_1 = require("./tabs");
var toggler_1 = require("./toggler");
require("bootstrap/scss/bootstrap");
require("../scss/main");
var Application = /** @class */ (function () {
    function Application() {
        this.initMobileMenu();
        this.initModalImage();
        this.initModalMedia();
        this.initScrollTo();
        this.initSlider();
        this.initStaticAssets();
        this.initTabs();
        this.initToggler();
    }
    Application.prototype.initMobileMenu = function () {
        var items = document.querySelectorAll('.toggler__menu-btn');
        items.forEach(function (item) {
            var element = item;
            if (!element.dataset.target) {
                return;
            }
            var target = document.querySelector(element.dataset.target);
            if (target === null) {
                return;
            }
            var body = document.querySelector('body');
            new toggler_1.Toggler({
                element: item,
                target: target,
                switchOn: function () {
                    if (body !== null) {
                        var overlay = document.createElement('div');
                        overlay.className = 'overlay';
                        body.appendChild(overlay);
                        body.classList.add('menu__opened');
                    }
                },
                switchOff: function () {
                    if (body !== null) {
                        var overlay = document.querySelector('.overlay');
                        if (overlay !== null) {
                            body.removeChild(overlay);
                        }
                        body.classList.remove('menu__opened');
                    }
                }
            }).run();
        });
    };
    Application.prototype.initSlider = function () {
        var items = document.querySelectorAll('.main-slider_instance');
        items.forEach(function (item) {
            var element = item;
            var navigation = {};
            var tabContainer = element.closest('.tab__container');
            if (tabContainer) {
                var prevEl = tabContainer.querySelector('.aircrafts-slider__button-prev');
                var nextEl = tabContainer.querySelector('.aircrafts-slider__button-next');
                if (prevEl) {
                    navigation['prevEl'] = prevEl;
                }
                if (nextEl) {
                    navigation['nextEl'] = nextEl;
                }
            }
            new swiper_1.SwiperSlider({
                container: element,
                options: {
                    observer: true,
                    observeParents: true,
                    loop: true,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: true,
                    },
                    slidesPerView: 2,
                    navigation: navigation,
                    breakpoints: {
                        0: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        539: {
                            slidesPerView: 2,
                            spaceBetween: 15
                        },
                        767: {
                            slidesPerView: 3,
                            spaceBetween: 15
                        },
                        991: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                        1199: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        },
                        1330: {
                            slidesPerView: 4,
                            spaceBetween: 40
                        }
                    }
                }
            }).run();
        });
    };
    Application.prototype.initModalImage = function () {
        new image_1.ModalImage({
            elementsSelector: '.fancybox__image',
            options: {
                smallBtn: true
            }
        }).run();
    };
    Application.prototype.initModalMedia = function () {
        new media_1.ModalMedia({
            elementsSelector: '.fancybox__media'
        }).run();
    };
    Application.prototype.initTabs = function () {
        new tabs_1.Tabs({
            selector: '.tabs__list a'
        }).run();
    };
    Application.prototype.initToggler = function () {
        var items = document.querySelectorAll('.toggler__btn');
        items.forEach(function (item) {
            var element = item;
            if (!element.dataset.target) {
                return;
            }
            var target = document.querySelector(element.dataset.target);
            if (target === null) {
                return;
            }
            new toggler_1.Toggler({
                element: item,
                target: target
            }).run();
        });
    };
    Application.prototype.initScrollTo = function () {
        var items = document.querySelectorAll('.scroll__btn');
        items.forEach(function (item) {
            var element = item;
            if (element.dataset.scrollHref) {
                var scrollTarget = document.querySelector(element.dataset.scrollHref);
                if (scrollTarget !== null) {
                    new scroll_to_1.ScrollTo({
                        triggerElement: item,
                        destination: scrollTarget,
                        ease: ease_component_1.inOutSine,
                        scrollDuration: element.dataset.scrollDuration ? parseInt(element.dataset.scrollDuration) : 1000
                    }).run();
                }
            }
        });
    };
    Application.prototype.initStaticAssets = function () {
        new static_assets_1.StaticAssets().run();
    };
    return Application;
}());
document.addEventListener('DOMContentLoaded', function (event) {
    var application = new Application();
});
